// stores/cityselectStore.js
import { defineStore } from "pinia";

export const useCityselectStore = defineStore("cityselect", {
  state: () => ({
    locations: [
      {
        id: 1,
        name: "Abu Dhabi",
        cities: [
          {
            id: 2,
            name: "Abu Dhabi City",
            districts: [
              { id: 10101, name: "Al Mushrif" },
              { id: 10102, name: "Al Khalidiyah" },
              { id: 10103, name: "Bain Al Jesrain" },
            ],
          },
          {
            id: 4,
            name: "Al Ain",
            districts: [
              { id: 10201, name: "Al Jimi" },
              { id: 10202, name: "Al Mutaredh" },
              { id: 10203, name: "Al Hili" },
            ],
          },
          {
            id: 13,
            name: "Madinat Zayed",
            districts: [
              { id: 10201, name: "Al Jimi" },
              { id: 10202, name: "Al Mutaredh" },
              { id: 10203, name: "Al Hili" },
            ],
          },
          {
            id: 14,
            name: "Ruwais",
            districts: [
              { id: 10201, name: "Al Jimi" },
              { id: 10202, name: "Al Mutaredh" },
              { id: 10203, name: "Al Hili" },
            ],
          },
          {
            id: 15,
            name: "Liwa Oasis",
            districts: [
              { id: 10201, name: "Al Jimi" },
              { id: 10202, name: "Al Mutaredh" },
              { id: 10203, name: "Al Hili" },
            ],
          },
          {
            id: 17,
            name: "Ghayathi",
            districts: [
              { id: 10201, name: "Al Jimi" },
              { id: 10202, name: "Al Mutaredh" },
              { id: 10203, name: "Al Hili" },
            ],
          },
        ],
      },
      {
        id: 2,
        name: "Dubai",
        cities: [
          {
            id: 1,
            name: "Dubai City",
            districts: [
              { id: 20101, name: "Deira" },
              { id: 20102, name: "Jumeirah" },
              { id: 20103, name: "Downtown Dubai" },
            ],
          },
          {
            id: 11,
            name: "Jebel Ali",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
          {
            id: 20,
            name: "Hatta",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
        ],
      },
      {
        id: 3,
        name: "Sharjah",
        cities: [
          {
            id: 3,
            name: "Sharjah",
            districts: [
              { id: 20101, name: "Deira" },
              { id: 20102, name: "Jumeirah" },
              { id: 20103, name: "Downtown Dubai" },
            ],
          },
          {
            id: 9,
            name: "Khor Fakkan",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
          {
            id: 10,
            name: "Kalba",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
          {
            id: 16,
            name: "Dhaid",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
          {
            id: 19,
            name: "Dibba Al-Hisn",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
          {
            id: 21,
            name: "Al Madam",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
        ],
      },
      {
        id: 4,
        name: "Umm al-Qaiwain",
        cities: [
          {
            id: 4,
            name: "Umm al-Qaiwain",
            districts: [
              { id: 20101, name: "Deira" },
              { id: 20102, name: "Jumeirah" },
              { id: 20103, name: "Downtown Dubai" },
            ],
          },
          {
            id: 202,
            name: "Hatta",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
        ],
      },
      {
        id: 5,
        name: "Fujairah",
        cities: [
          {
            id: 7,
            name: "Fujairah",
            districts: [
              { id: 20101, name: "Deira" },
              { id: 20102, name: "Jumeirah" },
              { id: 20103, name: "Downtown Dubai" },
            ],
          },
          {
            id: 12,
            name: "Dibba Al-Fujairah",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
        ],
      },
      {
        id: 6,
        name: "Ajman",
        cities: [
          {
            id: 5,
            name: "Ajman City",
            districts: [
              { id: 20101, name: "Deira" },
              { id: 20102, name: "Jumeirah" },
              { id: 20103, name: "Downtown Dubai" },
            ],
          },
          {
            id: 202,
            name: "Hatta",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
        ],
      },
      {
        id: 7,
        name: "Ra’s al-Khaimah",
        cities: [
          {
            id: 6,
            name: "RAK City",
            districts: [
              { id: 20101, name: "Deira" },
              { id: 20102, name: "Jumeirah" },
              { id: 20103, name: "Downtown Dubai" },
            ],
          },
          {
            id: 18,
            name: "Ar-Rams",
            districts: [{ id: 20201, name: "Hatta Fort" }],
          },
        ],
      },
      // ... Add other states here
    ],
    selectedState: null,
    selectedCity: null,
    selectedDistrict: null,
    business_type: [
      { code: "003", name: "Retail" },
      { code: "004", name: "Personal" },
      { code: "106", name: "Wholesale" },
      { code: "107", name: "Wholesale&Retail" },
    ],
    core_business: [
      { code: "005", name: "Electronics" },
      { code: "011", name: "Motor Vehicle" },
      { code: "013", name: "Mobile Phones & Accessories" },
      { code: "015", name: "Home Electronics" },
      { code: "023", name: "Food & Beverages" },
      { code: "025", name: "Health & Beauty" },
      { code: "027", name: "Home & Garden" },
      { code: "031", name: "Construction & Real Estate" },
      { code: "033", name: "Finance & Banking" },
      { code: "035", name: "Insurance & Superannuation" },
      { code: "037", name: "Media & Entertainment" },
      { code: "041", name: "Professional Services" },
      { code: "043", name: "Publishing & Advertising" },
      //... Add other core business categories here
    ],
  }),
  getters: {
    filteredCities(state) {
      if (!state.selectedState) {
        return [];
      }
      const selectedState = state.locations.find(
        (location) => location.id === state.selectedState
      );
      return selectedState ? selectedState.cities : [];
    },
    filteredDistricts(state) {
      if (!state.selectedCity) return [];
      const selectedCity = state.filteredCities.find(
        (city) => city.id === state.selectedCity
      );
      return selectedCity ? selectedCity.districts : [];
    },
    getBusinessType(state) {
      return state.business_type;
    },
    getCoreBusiness(state) {
      return state.core_business;
    },
  },
  actions: {
    setSelectedState(stateId) {
      this.selectedState = stateId;
      this.selectedCity = null;
      this.selectedDistrict = null;
    },
    setSelectedCity(cityId) {
      this.selectedCity = cityId;
      this.selectedDistrict = null;
    },
    setSelectedDistrict(districtId) {
      this.selectedDistrict = districtId;
    },
  },
});
