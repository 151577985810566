<template>
  <div class="container is-fluid">
    <div class="columns">
      <div class="box">
        <div class="column p-0">
          <article class="media">
            <figure class="media-left is-align-self-center">
              <p
                class="image is-128x128 has-text-centered"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  v-if="termDetails.category && termDetails.category.icon_image"
                  :src="termDetails.category.icon_image"
                  alt="Category Icon"
                  style="height: 120px; width: auto"
                />
                <img
                  v-else
                  src="https://bulma.io/assets/images/placeholders/256x256.png"
                  alt="Placeholder Image"
                />
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <h6 class="mb-0">ID# {{ searchterm_id }}</h6>
                <h4 class="mb-1">Hello {{ $currentUser().name }}</h4>
                <p>
                  <!-- <strong></strong> -->
                  <small> {{ $currentUser().email }}</small>
                  <!-- <small>31m</small> -->
                  <br />
                  We understood that you have searched for
                  <strong> {{ termDetails.term }}</strong> in your area. Please
                  wait for responses while we are contacting all our sellers and
                  service providers in your area. Upon confirming the
                  availability, you will see seller details below. Please feel
                  free to chat and discuss your enquiry in detail with them.
                </p>
                <!-- <p>{{ selected_term_response }}</p> -->
                <b-field grouped>
                  <div class="control">
                    <b-taglist attached>
                      <b-tag type="is-dark">npm</b-tag>
                      <b-tag type="is-info">0.5.1</b-tag>
                    </b-taglist>
                  </div>
                  <div class="control">
                    <b-taglist attached>
                      <b-tag type="is-dark">downloads</b-tag>
                      <b-tag type="is-success">15k</b-tag>
                    </b-taglist>
                  </div>
                </b-field>
              </div>
              <nav class="level is-mobile">
                <div class="level-left">
                  <a class="level-item">
                    <span class="icon is-small"
                      ><i class="fas fa-reply"></i
                    ></span>
                  </a>
                  <a class="level-item">
                    <span class="icon is-small"
                      ><i class="fas fa-retweet"></i
                    ></span>
                  </a>
                  <a class="level-item">
                    <span class="icon is-small"
                      ><i class="fas fa-heart"></i
                    ></span>
                  </a>
                </div>
              </nav>
            </div>
          </article>
        </div>
        <div class="columns">
          <div class="column">
            <nav class="level">
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Sellers Notified</p>
                  <p class="title">
                    {{
                      termDetails.notified_count
                        ? termDetails.notified_count
                        : 0
                    }}
                  </p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Opened Enquiry</p>
                  <p class="title">123</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Responses</p>
                  <p class="title">
                    {{
                      termDetails.responses ? termDetails.responses.length : 0
                    }}
                  </p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Updating</p>
                  <p class="title">789</p>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true">
      <VueSpinnerHourglass size="40" color="red" />
    </b-loading> -->

    <!-- <b-loading v-model="isLoading" :is-full-page="isFullPage" :can-cancel="true">
    <VueSpinnerHourglass size="40" color="red" />
  </b-loading> -->

    <div class="columns">
      <div class="column is-half px-0">
        <section v-if="!isLoading">
          <!-- <h1>Search Results for "{{ searchterm_id }}"</h1>
          <h4>Searched For : {{ termDetails.term }}</h4> -->
          <!-- <h5>Test {{ searchResults.responses }}</h5> -->
          <!-- <h6>Term Details {{ termDetails }}</h6>
            <h6>Showable Results {{ showable_results }}</h6> -->
          <!-- <h5>Updated On : {{ formatDate(termDetails.lacal_cached) }}</h5>
          <div v-if="search_results_status === 'SELLERS_FOUND'">
            <span class="icon has-text-success">
              <i class="fas fa-check"></i>
            </span>
            Available.
            {{ termDetails.responses ? termDetails.responses.length : 0 }}
            seller{{
              termDetails.responses && termDetails.responses.length > 1
                ? "s"
                : ""
            }}
            found!
          </div> -->
          <!-- <button class="button is-danger" @click="cancelSearch">
            Cancel Search
          </button> -->
          <!-- <ul>
              <li v-for="response in searchResults.responses" :key="response.id">
                <a :href="'/product/' + response.id">{{ response.seller_id }}</a>
                <p>{{ response.price }}</p>
                <p>{{ response.term }}</p>
              </li>
            </ul> -->
          <!-- <div>
              {{ showable_results }}
            </div> -->
          <div class="box">
            <!-- Main container -->
            <nav class="level">
              <!-- Left side -->
              <div class="level-left">
                <div class="level-item">
                  <p class="subtitle is-5"><strong>Seller Responded</strong></p>
                </div>
                <!-- <div class="level-item">
                  <div class="field has-addons">
                    <p class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Find a post"
                      />
                    </p>
                    <p class="control">
                      <button class="button">Search</button>
                    </p>
                  </div>
                </div> -->
              </div>

              <!-- Right side -->
              <div class="level-right">
                <p class="level-item"><strong>All Partners</strong></p>
                <p class="level-item"><a>Premium</a></p>
                <p class="level-item"><a>Basic</a></p>
                <!-- <p class="level-item"><a>Deleted</a></p> -->
                <div class="field">
                  <div class="control">
                    <div class="select">
                      <select>
                        <option>Price</option>
                        <option>Location</option>
                        <option>Rating</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <article
              class="media p-2 term_response"
              v-for="term in showable_results"
              :key="term.id"
              :class="{
                'has-background-success-light':
                  term.id === selected_term_response.id,
              }"
            >
              <figure class="media-left">
                <p
                  class="image is-128x128"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <img
                    v-if="term.seller && term.seller.logo"
                    :src="imageUrl(term.seller.logo)"
                    alt="Category Icon"
                    style="height: 60px; width: auto"
                  />
                  <img
                    v-else
                    src="https://bulma.io/assets/images/placeholders/256x256.png"
                    alt="Placeholder Image"
                  />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <h4 class="title is-4 mb-2">
                    {{ term.seller.business_name }}
                  </h4>
                  <p class="mb-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <p class="has-background-white-ter has-text-primary mb-0">
                    <span class="mdi mdi-map"></span>
                    {{ term.seller.city.name }} , United Arab Emirates
                  </p>

                  <b-rate
                    v-model="term.seller.rating"
                    :icon-pack="packs"
                    :icon="icons"
                    :max="5"
                    :size="sizes"
                    :locale="locale"
                    :show-score="score"
                    :custom-text="custom"
                    :show-text="text"
                    :texts="texts"
                    :rtl="isRtl"
                    :spaced="isSpaced"
                    :disabled="isDisabled"
                  >
                  </b-rate>
                </div>
              </div>
              <div class="media-right is-flex is-flex-direction-column">
                <h5 class="title is-6 py-2 mb-1 has-text-centered">
                  {{ formatAmount(term.price) }} AED
                </h5>
                <button
                  class="button is-primary"
                  :disabled="term.id === selected_term_response.id"
                  @click.prevent="selected_term_response = term"
                >
                  <span class="icon">
                    <i class="mdi mdi-chat"></i>
                  </span>
                  <span>Chat with Seller</span>
                </button>
              </div>
            </article>

            <div class="" v-for="term in showable_results" :key="term.id">
              <div
                class="columns"
                v-on:click.prevent="
                  // markForEnquiry(term);
                  onClickEnquiry(term)
                "
              >
                <div class="column is-narrow">
                  <!-- <img class="sr-image-width" :src="imageUrl(term.seller.logo)" /> -->
                </div>
                <!-- <div class="column p-1">
                  <div>
                    <span>{{ term.seller.business_name }}</span>
                  </div>
                  <div>
                    <i class="fas fa-map-pin fa-sm"></i> &nbsp;<span
                      class="is-size-7"
                      >{{ term.seller.city.name }} -
                      {{ (term.distance / 1000).toFixed(2) }} Km</span
                    >
                  </div>
                  <div>
                    <span class="is-size-7">{{ term.seller.plan }}</span>
                  </div>
                  <div>
                    <b-rate size="is-small" custom-text="100 Reviews"></b-rate>
                  </div>
                </div>
                <div class="column is-narrow">
                  <span class="has-text-danger has-text-weight-semibold"
                    >{{ formatAmount(term.price) }} AED</span
                  >
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div>
            <i
              v-if="searchResults.status == 'notified'"
              class="fas fa-spinner fa-pulse"
            ></i>
            {{ search_status }}
          </div> -->
          <!-- <b-loading
            :is-full-page="isFullPage"
            v-model="isLoading"
            :can-cancel="true"
          >
            <VueSpinnerHourglass size="40" color="red" />
          </b-loading> -->
        </section>
      </div>

      <div class="column is-half">
        <div class="box" style="height: 100%" v-if="!isLoading">
          <h3>Chat</h3>
          <EnquiryChat
            :sellerResponse="selected_term_response"
            :term="termDetails"
          />
        </div>
        <!-- <enquiry-chat
            :seller-response="selected_term_response"
            :term="termDetails"
          >
          </enquiry-chat> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { useSearchStore, useAuthStore } from "@/store";
import { computed } from "vue";
import { useRoute } from "vue-router";
import EnquiryChat from "@/components/EnquiryChat.vue";
import { DialogProgrammatic as Dialog } from "buefy";
import { VueSpinnerHourglass } from "vue3-spinners";
// import PageLoader from "@/components/PageLoader.vue";
import { defineAsyncComponent, toRaw } from "vue";
import loader from "sass-loader";
import io from 'socket.io-client';

const PageLoader = defineAsyncComponent(() =>
  import("@/components/PageLoader.vue")
);

export default {
  props: {
    searchterm_id: {
      type: String,
      required: true,
    },
  },
  components: {
    EnquiryChat,
    PageLoader,
    VueSpinnerHourglass,
  },
  data() {
    return {
      searchResults: [], // Initialize searchResults
      // searchtermIdFromRoute: this.$route.params.searchterm_id, // Get searchterm_id from route params
      searchStore: useSearchStore(), // Assign searchStore to this
      termDetails: {
        responses: [],
      },
      rate: 4.6,
      maxs: 5,
      sizes: '',
      packs: 'mdi',
      icons: 'star',
      selected_term_response: null,
      term_id: this.$route.params.id,
      product_listing: true,
      isLoading: false,
      isFullPage: false,
      enquiries: {
        loading: true,
        data: [],
        pagination: {},
      },
      cart: null,
      product_detail: null,
      sort_order: 2,
      selectedResponseExpiryTimer: {
        value: 0,
        intervalId: null,
      },
      meta: {
        actions: {
          onPageLoad: {
            loading: false,
          },
          onProceedAction: {
            loading: false,
          },
        },
      },
    };
  },
  computed: {
    search_results_status: function () {
      if (this.searchResults.responses && this.searchResults.responses.le > 0) {
        return "SELLERS_NOT_FOUND";
      }
      return "SELLERS_FOUND";
    },
    search_status: function () {
      if (this.searchResults.status === "cancelled") {
        return "Cancelled";
      }
      return "Searching";
    },
    showable_results: function () {
      const responses = this.termDetails.responses;
      return responses;
    },

    selected_response_timer_display: function () {
      if (this.selectedResponseExpiryTimer.value > 0) {
        const diff = moment.duration(
          this.selectedResponseExpiryTimer.value,
          "seconds"
        );
        return `${diff.days()}d ${diff.hours()}h ${diff.minutes()}m`;
      }
      return "0 seconds";
    },
  },
  async created() {
    console.log("SearResult Vue is created, but not yet mounted to DOM");
    // this.interval = setInterval(() => this.fetchSearchResponses(), 10000)
    this.initializeWebSocket();
    await this.fetchSearchResponses();
  },
  async mounted() {
    console.log("SearResult Vue is mounted and available in the DOM");
    console.log(
      "When mounted we found the User :: " + this.$currentUser().name
    );
    // console.log(this.termDetails)
    // await this.fetchTermInfo();

    // await this.fetchSearchResponses();
    // // const reqData = {
    // //   clear: 1
    // // }
    // // this.cart = await CartService.getCart(reqData)
    // // this.meta.actions.onPageLoad.loading = false
    // setTimeout(() => {
    //   this.meta.actions.onPageLoad.loading = false; // Disable loader after 3 seconds
    // }, 3000); // Adjust timing based on your actual loading process
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    async cancelSearch() {
      try {
        const { result, dialog } = await this.$buefy.dialog.confirm({
          title: "Cancel Search",
          message: "Are you sure you want to <b>cancel</b> your search?",
          closeOnConfirm: false,
          hasIcon: true,
          onConfirm: function (result, dialog) {
            dialog.close();
            this.searchStore.cancel(this.searchterm_id);
          },
        });
        // if (result) {
        //   console.log(result);
        //   this.$buefy.dialog.close();
        //   // await SearchService.cancel(this.searchterm_id);
        // }
      } catch (e) {
        console.log(e);
      }
    },
    initializeWebSocket() {
      // Replace with the URL of your Node.js server
      this.socket = io(process.env.VUE_APP_WEBSOCKET_URL);
      // Subscribe to updates for a specific search term ID
      this.socket.emit('subscribeToSearchTerm', this.searchterm_id);
      // Listen for updates from the server
      this.socket.on('SearchTermResponseUpdate', (data) => {
        console.log('Received search term response update:', data);
        this.updateData = data;
        // this.fetchSearchResponses();
        // {{ edit_1 }}: Check if data.id exists in termDetails.responses
        const exists = this.termDetails.responses.some(response => response.id === data.id);
        if (this.termDetails.responses.length === 0) {
          this.termDetails.responses.push(data);
          this.isLoading = false;
        }
        if (!exists) {
            if (this.termDetails.responses.length === 0) {
                this.termDetails.responses.push(data); // Add data if responses array is empty
            }
        }
        // {{ edit_1 }}: Check if the response ID does not exist and push data if conditions are met
        if (this.termDetails.responses.length > 0 && this.termDetails.responses.some(response => response.id !== data.id)) {
            this.termDetails.responses.push(data); // Add data if response ID is not found
        }
        if (
          this.showable_results.length > 0 &&
          this.selected_term_response == null
        ) {
          this.selected_term_response = this.showable_results[0];
        } else if (this.selected_term_response !== null) {
          // find the ID from the results and set to the selected_term_response
          this.selected_term_response = _.find(this.showable_results, {
            id: this.selected_term_response.id,
          });
        }
        // Add toast notification
        this.$buefy.toast.open({
            message: 'New response received!',
            type: 'is-success',
            duration: 5000,
        });
      });
    },
    logSearchResults() {
      console.log("Testing ......");
      console.log(this.searchResults);
      // this.searchResults.responses.forEach(response => {
      //   console.log(response);
      // });
    },
    onLoaderCancel() {
      // Handle cancellation of the loader
      this.meta.actions.onPageLoad.loading = false;
      console.log("Loader was canceled");
    },
    async markForEnquiry(stResponse) {
      try {
        // stResponse._meta.actions.onProceed.loading = true
        await SearchService.markForEnquiry(stResponse.id);
      } catch (e) {
        this.errors = e.response;
        console.log("this.errors", e.response);
      }
    },
    onClickEnquiry(term) {
      console.log(term);
      this.product_detail = term;
    },
    async fetchUserData() {
      const authStore = useAuthStore();
      const userData = await authStore.getUserDataFromLocalStore(); // Call the new method
      const userId = userData ? userData.id : null; // Safely access user ID

      // Fetching logic
      this.fetchSearchResults(userData);
    },
    async fetchSearchResults(userData) {
      this.termDetails = await this.searchStore.retriveQuickSearchResults(
        userData.id,
        parseInt(this.searchterm_id)
      );
    },
    async fetchSearchResponses() {
      this.isLoading = true;
      if (
        !this.termDetails.responses ||
        this.termDetails.responses.length === 0
      ) {
        const response = await this.searchStore.viewSearchTerm(
          this.searchterm_id
        );
        this.termDetails = response;
        if (this.termDetails.responses.length > 0) {
          this.isLoading = false;
        }
        // Select the first response as default when there are results
        if (
          this.showable_results.length > 0 &&
          this.selected_term_response == null
        ) {
          this.selected_term_response = this.showable_results[0];
        } else if (this.selected_term_response !== null) {
          // find the ID from the results and set to the selected_term_response
          this.selected_term_response = _.find(this.showable_results, {
            id: this.selected_term_response.id,
          });
        }
      } else {
        this.isLoading = true;
        // You might want to handle the response here as well
      }
    },
    getCurrentUserId() {
      // Access the global currentUser
      const currentUserId = this.$currentUser().id;

      if (currentUserId) {
        console.log("Current User ID:", currentUserId);
      } else {
        console.log("User is not authenticated");
      }

      // You can now use the currentUserId for your logic
    },
    fetchTermInfo() {
      // try {
      //   let sort_order_param = null
      //   switch (this.sort_order) {
      //     case 1:
      //       sort_order_param = 'price-asc-rank'
      //       break
      //     case 2:
      //       sort_order_param = 'distance-asc-rank'
      //   }
      //   const response = await SearchService.viewSearch(this.term_id, { params: { sort: sort_order_param } })
      //   this.termDetails = response

      //   if (this.showable_results.length > 0 && this.selected_term_response == null) {
      //     this.selected_term_response = this.showable_results[0]
      //   } else if (this.selected_term_response !== null) {
      //     // find the ID from the results and set to the selected_term_response
      //     this.selected_term_response = _.find(this.showable_results, { id: this.selected_term_response.id })
      //   }
      // } catch (e) {
      //   this.errors = e.response
      // } finally {
      //   this.loader = false
      // }
      if (this.termDetails.responses.length > 0) {
        this.selected_term_response = this.termDetails.responses[0];
        console.log("Selected the first response from termDetails.responses");
      } else if (this.selected_term_response !== null) {
        // find the ID from the results and set to the selected_term_response
        this.selected_term_response = _.find(this.showable_results, {
          id: this.selected_term_response.id,
        });
        console.log(
          "Found the ID from the results",
          this.selected_term_response
        );
      }
    },
  },
};
</script>

<style>
.term_response {
  border-radius: 5px;
}
</style>