/* eslint-disable */
import axios from "axios";
import Cookies from "universal-cookie";

// Retrieve the token from localStorage
const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
const token = storedData.authToken; // Get the auth token from findhub_spa
const cookies = new Cookies(); // Create an instance of universal-cookie

// Set the token in the Authorization header

export class EnquiryChatService {
  // Fetch the active chat thread
  static async getActiveThread(data) {
    const response = await axios.post(
      `/api/customer/enquiry-chat/get-thread`,
      data,
      {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  // Post a new message to the chat thread
  static async postNewMessage(data) {
    const response = await axios.post(
      `/api/customer/enquiry-chat/message`,
      data,
      {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  // Get messages for a specific thread by thread ID
  static async getThreadMessages(threadId) {
    const response = await axios.get(
      `/api/customer/enquiry-chat/thread/${threadId}/messages`,
      {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
}
