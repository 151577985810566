<template>
  <div class="app-layout findhub_spa">
    <header class="app-header">
      <!-- Header Content -->
      <Nav />
    </header>
    <main class="app-main">
      <!-- Main Content -->
      <router-view />
    </main>
    <footer class="app-footer">
      <!-- Footer Content -->
      <FooterHome />
    </footer>
  </div>

  <!-- <section class="hero is-fullheight">
       <div class="hero-head"></div>
    <div class="hero-body py-2">
      </div>
    <div class="hero-foot">
      <FooterHome />
    </div>
  </section> -->
</template>

<script>
/* eslint-disable */
import { useAuthStore } from "./store/auth";
import Nav from "./components/Nav.vue";
import FooterHome from "./views/layout/FooterHome.vue";
import { messaging } from "@/services/firebaseService"; // Import your Firebase messaging
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import io from 'socket.io-client';


// window.Pusher = require('pusher-js');
// Pusher.logToConsole = true; // Enable Pusher debug logging

// // Initialize Laravel Echo globally with Pusher
// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: '0831ba376a02083bbb94',
//   cluster: 'ap2',
//   forceTLS: true
// });

export default {
  name: "App",
  components: {
    Nav,
    FooterHome,
  },
  data() {
    return {
      searchterm_id: 1, // Replace with the actual search term ID you want to subscribe to
      updateData: null,
    };
  },
  created() {
    // this.initializeWebSocket();
  },
  // beforeUnmount() {
  //   if (this.socket) {
  //     this.socket.disconnect();
  //   }
  // },
  // setup() {
  //   const authStore = useAuthStore();

  //   // Check the user's authentication status when the page is loaded
  //   authStore.checkAuthStatus();

  //   return {
  //     user: authStore.user,
  //   };
  // },
  mounted() {
    const authStore = useAuthStore();

    // Ensure the user ID is up to date
    const userId = authStore.user?.id || 1; // Use logged-in user ID or default to 1 for testing

    // Log the current authentication state
    messaging.onMessage((payload) => {
      console.log("Message received in the foreground: ", payload);
      const notificationTitle = payload.notification?.title;
      const notificationBody = payload.notification?.body;
      console.log("Notification Body:", notificationBody); // Log the entire body

      // Extract the ID from the notification body and log it
      const id = notificationBody; // Assuming the body contains the ID directly
      console.log("Extracted ID from notification body:", id);

      // Show toast notification
      this.$buefy.toast.open(`${notificationTitle}: ${notificationBody}`);
    });

    // this.initializeWebSocket();
    // Laravel Echo: Listen to Public and Private Channels

    // Listen for the public event
    // Listen to the public channel
    // window.Echo.channel('public-channel')
    //   .listen('.TestPublicEvent', (data) => {
    //     console.log('Received public event data:', data);
    //     this.$buefy.toast.open(`Public Channel: ${data.message}`);
    //   });

    // // Listen to the private channel for the authenticated user
    // window.Echo.private(`private-channel.${userId}`)
    //   .listen('.TestPrivateEvent', (data) => {
    //     console.log(`Received private event for user ${userId}:`, data);
    //     this.$buefy.toast.open(`Private Channel [User ${userId}]: ${data.message}`);
    //   });
    // window.Pusher = require("pusher-js");
    // window.Echo = new Echo({
    //   broadcaster: "pusher",
    //   key: "0831ba376a02083bbb94",
    //   cluster: "ap2",
    //   forceTLS: true,
    // });
    // // console.log('Laravel Echo initialized, listening for events.');
    // window.Echo.channel("toast-public-channel").listen(
    //   ".ToastApproved",
    //   (e) => {
    //     console.log("Received toast notification:", e);
    //     this.toast = e;
    //   }
    // );
  },
  methods: {
    // initializeWebSocket() {
    //   // Replace with the URL of your Node.js server
    //   this.socket = io('http://localhost:3000');

    //   // Subscribe to updates for a specific search term ID
    //   this.socket.emit('subscribeToSearchTerm', this.searchterm_id);

    //   // Listen for updates from the server
    //   this.socket.on('SearchTermResponseUpdate', (data) => {
    //     console.log('Received search term response update:', data);
    //     this.updateData = data;
    //   });
    // },
  },

};
</script>

<style lang="scss">
.findhub_spa {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  // background: linear-gradient(rgba(70, 246, 143, 0.432), rgba(13, 87, 2, 0.5)), url('@/assets/img/fc_main_bg.jpg') no-repeat center center;  
  background-size: cover;

  .app-header {
    height: 10%;
    // background: rgba(255, 255, 255, 0.8); // Semi-transparent overlay with brand color
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .app-main {
    height: 80%;
    // background: rgba(240, 239, 239, 0.8); // Semi-transparent overlay with brand color
    padding: 2rem;
    flex-grow: 1;
    overflow-y: auto;
  }

  .app-footer {
    height: 10%;
    background-color: #163042; // Use your brand colors here
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
}
</style>