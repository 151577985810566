<template>
  <div class="container">
    <b-breadcrumb align="is-centered">
      <b-breadcrumb-item href="/">Home</b-breadcrumb-item>
      <b-breadcrumb-item href="/documentation/breadcrumb" active>
        Contact Us
      </b-breadcrumb-item>
    </b-breadcrumb>
    <section class="hero is-primary">
      <div class="hero-body">
        <p class="title">Contact Us</p>
      </div>
    </section>
    <div class="columns">
      <div class="column">
        <div class="box">
          <figure class="image is-128x128">
            <img
              src="https://bulma.io/assets/images/placeholders/256x256.png"
            />
          </figure>
          <div class="has-text-centered">
            <div class="my-5">
              <h3 class="text-h6 is-size-3 mb-0">Get in Touch</h3>
              <p class="is-size-7">Feel free to drop us a line below</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <section>
          <div class="container">
            <div class="column is-half">
              <b-field>
                <b-input placeholder="Name" v-model="name"></b-input>
              </b-field>
              <b-field>
                <b-input
                  type="email"
                  placeholder="Email"
                  v-model="email"
                ></b-input>
              </b-field>
              <b-field>
                <b-select
                  placeholder="Type of Issue"
                  v-model="issue_type"
                  expanded
                >
                  <option
                    v-for="option in referenceCodes.data"
                    :key="option.name"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field>
                <b-input
                  maxlength="200"
                  type="textarea"
                  placeholder="Message"
                  v-model="message"
                ></b-input>
              </b-field>
              <b-button
                v-on:click="onSubmit()"
                icon-right="paper-plane"
                icon-pack="fa"
                type="button is-primary is-primary-addon-1"
                >Send</b-button
              >
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import { ContactUsService } from "@/services/contact_us.service";
import { useRefCodeStore } from "@/store";

export default {
  components: {},
  name: "ContactUs",
  data: function () {
    return {
      contact_form_issuetype: [],
      errors: [],
      name: null,
      email: null,
      data: null,
      issue_type: null,
      message: null,
      contact_us_form: {
        loader: false,
      },
      refCodeStore: null,
      referenceCodes: [], // Initialize referenceCodes as an empty array
    };
  },
  created() {
    this.initializeRefCodeStore();
  },
  methods: {
    async initializeRefCodeStore() {
      this.refCodeStore = useRefCodeStore();
      await this.refCodeStore.refreshReferenceCodes(["contact_form_issuetype"]);
      this.referenceCodes =
        this.refCodeStore.referenceCodes.contact_form_issuetype; // Store reference codes in a variable
    },
    async onSubmit() {
      const reqData = {
        name: this.name,
        email: this.email,
        issue_type: this.issue_type,
        message: this.message,
      };
      try {
        this.response = await ContactUsService.contactFormData(reqData);
        console.log(this.response);
        this.$buefy.notification.open({
          message: "Thank you for your message. It has been sent.",
          type: "is-success",
        });
        this.contact_us_form.loader = false;
        this.clearInputFields();
      } catch (e) {
        this.contact_us_form.loader = false;
      }
    },
    clearInputFields(e) {
      this.name = "";
      this.email = "";
      this.issue_type = "";
      this.message = "";
      e.preventDefault();
    },
  },
};
</script>

<style scoped></style>
