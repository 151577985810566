<template>
  <div class="modal-content">
    <div class="has-background-white box w-6">
      <div>
        <div v-if="files.file_id === null">
          <b-upload v-model="files.file_data" v-on:input="handleFileUploads">
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Click to upload</span>
            </span>
          </b-upload>
        </div>
        <div v-else>
          <img :src="imageUrl(files.file_id)" />
          <div>
            <b-button
              type="is-danger"
              size="is-small"
              outlined
              label="Delete"
              @click="onDelete"
            />
          </div>
        </div>
        <div class="columns mt-4">
          <div class="column has-text-centered">
            <div class="control">
              <b-button
                class="button is-primary is-primary-addon-1"
                @click="onSubmit"
              >
                Upload
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FileuploadService } from "@/services/fileupload.service";
// import { EnquiryChatService } from '@/services/enquiry-chat.service'
export default {
  name: "CustomSearchPopupModal",
  props: ["thread_id"],
  data: function () {
    return {
      files: {
        file_id: null,
        file_data: null,
      },
    };
  },
  methods: {
    async handleFileUploads() {
      this.files.file_id = null;
      if (this.files.file_data !== null) {
        const response = await FileuploadService.uploadFile(
          this.files.file_data
        );
        this.files.file_id = response.guid;
      }
    },
    async onSubmit() {
      try {
        this.$emit("close", this.files.file_id);
      } catch (e) {
        this.loader = false;
      }
    },
    onDelete() {
      this.files.file_id = null;
    },
  },
};
</script>
