import axios from "axios";
// import { useAuthStore } from "../store/auth"; // Import the Pinia store

// const checkAuthentication = () => {
//   const authStore = useAuthStore();
//   const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
//   const token = storedData.authToken; // Get the Bearer token from stored data

//   if (!authStore.isAuthenticated || !token) {
//     throw new Error("User is not logged in");
//   }
// };

export const elasticsearchService = {
  async autoSuggestElastic(query) {
    // checkAuthentication();

    const data = {
      query: {
        match: {
          term: query,
        },
      },
    };

    // Dynamically pick the server URL based on the environment
    const elasticsearchServer = process.env.VUE_APP_ELASTICSEARCH_SERVER;

    // Set up the headers
    const headers = {
      "Content-Type": "application/json",
      // Disable X-CSRF-Token by not including it in headers
    };

    // Add Basic Authorization header if in production
    if (process.env.NODE_ENV === "production") {
      const username = process.env.VUE_APP_ELASTICSEARCH_USERNAME;
      const password = process.env.VUE_APP_ELASTICSEARCH_PASSWORD;

      const authToken = btoa(`${username}:${password}`); // Encode to Base64

      headers["Authorization"] = `Basic ${authToken}`;
    }

    // Make the API request
    return await axios.get(
      `${elasticsearchServer}/search_term_details/_search`,
      {
        headers: headers,
        data: data,
        withCredentials: false, // Disable sending cookies and CSRF tokens
      }
    );
  },
};
