import axios from "axios";
import Cookies from "universal-cookie"; // for handling cookies
const cookies = new Cookies();
// const TOKEN_KEY = "authToken"; // Key for storing the token in localStorage

export class FileuploadService {
  static async uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `/api/seller/file/file-upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token
        },
        withCredentials: true, // Enable withCredentials
      }
    );
    return response.data;
  }

  static getFileUrl(file_id) {
    return "/api/seller/file/" + file_id;
  }
}
