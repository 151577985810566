/* eslint-disable */
import firebase from "firebase/compat/app"; // Use compat for older versions
import "firebase/compat/messaging"; // Use compat for messaging
import { Notification } from "@capacitor/core";

const firebaseConfig = {
  apiKey: "AIzaSyBJEVkb7eJlMBEhIvV402UJ1-vPMbAx1VA",
  authDomain: "learning-fcm-f8ddf.firebaseapp.com",
  projectId: "learning-fcm-f8ddf",
  storageBucket: "learning-fcm-f8ddf.appspot.com",
  messagingSenderId: "348739391050",
  appId: "1:348739391050:web:43ac0d429097156df760b3",
  measurementId: "G-ZF1TS5FXGJ",
};
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = firebase.messaging();

// Function to request notification permission
export const requestNotificationPermission = async () => {
  if (process.env.NODE_ENV === "development") {
    console.log("Notification permission requests are disabled in development.");
    return false;
  }

  if (!("Notification" in window)) {
    console.error("This browser does not support notifications.");
    return false;
  }

  if (typeof Notification.requestPermission !== "function") {
    console.error("Notification.requestPermission is not a function.");
    return false;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return true;
    } else {
      console.error("Notification permission denied.");
      return false;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return false;
  }
};

// Request FCM token (called when you need to get the FCM token)
export const requestFCMToken = async () => {
  const permissionGranted = await requestNotificationPermission();
  if (!permissionGranted) {
    return; // Exit if permission is not granted
  }

  try {
    const token = await messaging.getToken({
      vapidKey:
        "BNfw24sjHa_9j5O7FJTQ0mSOCLnSiqgitQDTsMiHrpyoER0nS7y5KAh7d8xRmlyQhCMsKGxdazXNRXcH88yWQgc",
    });

    if (token) {
      console.log("FCM Token:", token);
      // Save token to backend or local storage
      await saveTokenToBackend(token);
      return token;
    } else {
      console.error(
        "No FCM token available. Make sure permissions are granted."
      );
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

// Function to save FCM token to your backend (customize this)
const saveTokenToBackend = async (token) => {
  // Implement your API call to save the token
  // For example: await api.post('/save-token', { token });
};

// Handle foreground messages (use this in Vue components)
export const handleForegroundMessages = (callback) => {
  messaging.onMessage((payload) => {
    console.log("Message received in foreground: ", payload);
    if (callback) {
      callback(payload);
    }
  });
};

export { messaging };
