// import { defineStore } from "pinia";
// import searchService from "../services/search.service";

// export const useSearchStore = defineStore("searchStore", {
//   state: () => ({
//     historyResults: [],
//     currentSearchTermId: null,
//     quickSearchResult: null,
//     quickSearchResponses: [],
//     customSearchResults: [],
//     vehicleSearchResults: [],
//     categories: [],
//     imageUploads: [],
//   }),
//   actions: {
//     setHistoryResults(results) {
//       this.historyResults = results;
//     },
//     async quickSearch(data) {
//       console.log("called this function", data);
//       try {
//         console.log("quickSearch");
//         const response = await searchService.quickSearch(data);
//         this.currentSearchTermId = response.id;
//         this.quickSearchResult = response;
//         this.setQuickSearchResults(response.responses || []); // Use the method to set results
//         return response;
//       } catch (error) {
//         console.error("Error in quick search:", error);
//       }
//     },
//   },
//   persist: {
//     enabled: true, // Enables persistence
//     strategies: [
//       {
//         key: "searchStore", // The key used to store the data in localStorage
//         storage: localStorage, // Use sessionStorage if needed
//       },
//     ],
//   },
// });
/* eslint-disable */
import { defineStore } from "pinia";
import searchService from "../services/search.service";

import Localbase from "localbase";

let db = new Localbase("searchDB");

// const CACHE_EXPIRATION_MS = 50 * 60 * 1000; // 10 minutes cache expiration
const CACHE_EXPIRATION_MS = 5 * 60 * 1000; // 5 minutes cache expiration

export const useSearchStore = defineStore("searchStore", {
  state: () => ({
    historyResults: [],
    currentSearchTermId: null,
    quickSearchResult: null,
    quickSearchResponses: [],
    customSearchResults: [],
    vehicleSearchResults: [],
    categories: [],
    imageUploads: [],
    lastUpdated: null,
  }),

  actions: {
    setHistoryResults(results) {
      this.historyResults = results;
    },

    async quickSearch(data) {
      console.log("called this function", data);

      // Check for cached data in Localbase
      // const cachedData = await this.getCachedData("quickSearchResult");
      // if (cachedData) {
      //   console.log("Using cached quick search results");
      //   this.currentSearchTermId = cachedData.currentSearchTermId;
      //   this.quickSearchResult = cachedData.quickSearchResult;
      //   this.quickSearchResponses = cachedData.quickSearchResponses;
      //   return cachedData;
      // }

      // If no cached data or expired, make API call
      try {
        console.log("Fetching fresh quick search data from API");
        const response = await searchService.quickSearch(data);
        this.currentSearchTermId = response.id;
        this.quickSearchResult = response;
        this.setQuickSearchResults(response.responses || []);

        // Cache the response in Localbase

        return response;
      } catch (error) {
        console.error("Error in quick search:", error);
      }
    },

    setQuickSearchResults(results) {
      this.quickSearchResponses = results;
    },

    // Cache data in Localbase
    async cacheData(key, data) {
      const dataToCache = {
        ...data,
        lacal_cached: Date.now(),
      };
      await db.collection("quickSearch").add(dataToCache, key);
    },

    // Update cached data in Localbase
    async updateCacheData(data) {
      const response = await db // Added return statement
        .collection("quickSearch")
        .doc({ id: parseInt(data.id) })
        .set({
          ...data,
          lacal_cached: Date.now(), // Update lastUpdated timestamp
        });
      return response.data;
    },

    async updateQuickSearchResults(key) {
      try {
        const cachedData = await this.getCachedData(key);
        if (cachedData) {
          this.quickSearchResponses = cachedData.quickSearchResponses;
          await this.cacheData(key, cachedData);
        } else {
          console.log(`No cached data found for key: ${key}`);
        }
      } catch (error) {
        console.error(
          `Error updating quick search results for key: ${key}`,
          error
        );
      }
    },
    async viewAllSearchTerm(userID) {
      console.log("Calling Backend API for Search Result for All Searchs...");
      try {
        const response = await searchService.viewAllSearchTerm();
        console.log("Search Result from searchService :", response.data);
        for (const item of response.data) {
          // Check if cached data exists before updating or caching
          const cachedData = await db
            .collection("quickSearch")
            .doc({ id: item.id })
            .get();
          if (!cachedData) {
            console.log("No cached data ");
            this.cacheData(item.id, item);
          } else {
            //  this.cacheData(item.id, item); // Cache each item individually
          }
        }
        return response;
      } catch (error) {
        console.error("Error in viewSearchTerm:", error);
      }
    },
    async viewSearchTerm(termID) {
      console.log(`Calling Backend API for Search Result for ${termID}`);
      const convertedTermID = parseInt(termID); // Ensure termID is converted to an integer
      try {
        const response = await searchService.viewSearchTerm(termID);
        console.log("Search Result for ID:", response.id);
        // Check if cached data exists before updating or caching
        const cachedData = await db // Added await here
          .collection("quickSearch")
          .doc({ id: convertedTermID })
          .get(); // Ensure to await the get() call
        if (cachedData) {
          console.log("Cached data updating");
          const updated = await this.updateCacheData(response);

          return await this.getCachedData(response.user_id, response.id)// Return the cached data if it exists

        } else {
          console.log("No cached data found, caching data now");
          return this.cacheData("quickSearch", response.data); // Cache if no existing data
        }
        return response;
      } catch (error) {
        console.error("Error in viewSearchTerm:", error);
      }
    },
    async retriveQuickSearchResults(userID, searchTermID) {
      const cachedData = await this.getCachedData(userID, searchTermID);
      if (cachedData) {
        return cachedData;
      } else {
        return false; // Return new quick search results if no cached data is found
      }
    },
    async setQuickSearchResultsWithNew(key, newQuickSearchResults) {
      console.log(
        "The Previous quick search results was expired and updating with new quick search results"
      );
      this.quickSearchResponses = newQuickSearchResults;
      console.log(newQuickSearchResults);
      // Update the cache with new quick search results
      return await this.updateCacheData(newQuickSearchResults);
    },
    async deleteQuickSearchResults() {
      this.quickSearchResponses = [];
      await this.clearCachedData("quickSearch");
    },
    async cancel(id) {
      console.log("Cancelling search ID", id);
      return await searchService.cancel(id);
    },

    // Retrieve cached data from Localbase if it has not expired
    async getCachedData(userID, searchTermID) {
      try {
        let cached;
        if (searchTermID) {
          cached = await db
            .collection("quickSearch")
            .doc({ user_id: userID, id: searchTermID }); // Use both criteria
        } else {
          cached = await db.collection("quickSearch").doc({ user_id: userID }); // Use only user_id
        }

        const result = await cached.get(); // Fetch the cached data

        if (!result) {
          console.log(
            `No cached data found forr user_id: ${userID}${
              searchTermID ? " and id: " + searchTermID : ""
            }`
          );
          // return null; // Return null if no cached data is found
        }
        const now = Date.now();
        if (!result) {
          console.log("No cached data found forrrrr user_id: ${userID}");
        } else if (now - result.lacal_cached < CACHE_EXPIRATION_MS) {
          return result; // Return cached data if it's still valid
        } else {
          console.log(
            `Cached data for user_id: ${userID} has expired Trying to Refresh`
          );
          // then i want to check if there new data is available
          const response = await this.viewSearchTerm(result.id);
          // if there is new data then i want to update the cache
          if (response) {
            // this.setQuickSearchResultsWithNew(userID, response);
            return this.setQuickSearchResultsWithNew(userID, response);
          } else {
            console.log(
              `No new data found for user_id: ${userID}${
                searchTermID ? " and id: " + searchTermID : ""
              } , Deleting all caches`
            );

            cached.delete();
          }
          return null; // Return null if no cached data is found
        }
        return result;
      } catch (error) {
        console.error(
          `Error retrieving cached data for user_id: ${userID}${
            searchTermID ? " and id: " + searchTermID : ""
          }`,
          error
        );
        return null; // Ensure that no data is returned in case of error
      }
    },

    // Clear cached data from Localbase if necessary
    async clearCachedData(key) {
      await db.collection("cache").doc({ id: key }).delete();
    },
  },
});
