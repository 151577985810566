<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Login</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- Added login type selection -->
        <!-- <b-field label="Select Login Type">
          <b-select v-model="loginType">
            <option value="email">Email Login</option>
            <option value="otp">OTP Login</option>
          </b-select>
        </b-field> -->
        <p class="buttons">
          <button
            class="button"
            :class="{ 'is-active': loginType === 'otp' }"
            @click.prevent="loginType = 'otp'"
            :disabled="isOtpSent"
          >
            <span class="icon">
              <i class="mdi mdi-cellphone"></i>
            </span>
            <span>Mobile</span>
          </button>

          <button
            class="button"
            :class="{ 'is-active': loginType === 'email' }"
            @click.prevent="loginType = 'email'"
            :disabled="isOtpSent"
          >
            <span class="icon">
              <i class="mdi mdi-email"></i>
            </span>
            <span>Email</span>
          </button>

          <button
            class="button"
            :class="{ 'is-active': loginType === 'gmail' }"
            @click.prevent="loginType = 'gmail'"
            :disabled="isOtpSent"
          >
            <span class="icon">
              <i class="mdi mdi-gmail"></i>
            </span>
            <span>Gmail</span>
          </button>
        </p>

        <!-- Conditional rendering based on login type -->
        <template v-if="loginType === 'email'">
          <b-field label="Email">
            <b-input
              type="email"
              v-model="form.email"
              placeholder="Your email"
              required
            />
          </b-field>
          <b-field label="Password" class="mb-1">
            <b-input
              type="password"
              v-model="form.password"
              password-reveal
              placeholder="Your password"
              required
            />
          </b-field>
          <b-button label="Login" type="is-primary" @click="handleEmailLogin" />
        </template>

        <template v-else-if="loginType === 'otp'">
          <b-field v-if="!isOtpSent">
            <div class="control has-icons-left">
              <div class="select">
                <select>
                  <option selected>UAE(+971)</option>
                </select>
              </div>
              <div class="icon is-small is-left">
                <i class="mdi mdi-earth"></i>
              </div>
            </div>
            <b-input
              type="tel"
              placeholder="50 1234567"
              v-model="mobile_number"
            ></b-input>
            <p class="control">
              <b-button
                type="is-primary"
                label="Send"
                :class="{ 'is-loading': OTPisLoading }"
                @click="handleInitialAuth"
              />
            </p>
          </b-field>
          <v-otp-input
            v-if="isOtpSent"
            ref="otpInput"
            input-classes="otp-input"
            :conditionalClass="['one', 'two', 'three', 'four']"
            separator="-"
            inputType="letter-numeric"
            :num-inputs="4"
            v-model:value="otp"
            :should-auto-focus="true"
            :should-focus-order="true"
            @on-change="handleOnChange"
            @on-complete="handleOtpValidation"
            :placeholder="['#', '#', '#', '#']"
            :is-disabled="isDisabled"
          />
        </template>
      </section>

      <footer class="modal-card-foot">
        <b-button type="is-primary" expanded><strong>Sign up</strong></b-button>
      </footer>
    </div>
  </form>
</template>

<script>
/* eslint-disable */ 
import { useAuthStore } from "@/store";
import VOtpInput from "vue3-otp-input";
import * as hellojs from "hellojs";

export default {
  components: {
    VOtpInput,
  },
  data() {
    return {
      isComponentModalActive: false,
      loginType: 'otp', // New property to track login type
      mobile_number: "",
      otp: "",
      isOtpSent: false,
      isDisabled: false,
      requestId: null,
      loading: false,
      errorMessage: "",
      token: "",
      OTPisLoading: false,
      form: {
        email: "",
        password: "",
        device_name: "Web",
      },
    };
  },
  computed: {
    authStore() {
      return useAuthStore();
    },
  },
  methods: {
    logout() {
      this.authStore.logout();
      this.$router.push("/");
    },
    async handleInitialAuth() {
      this.OTPisLoading = true;
      try {
        const response = await this.authStore.initiateOtpAuth(
          this.mobile_number
        );
        this.isOtpSent = true;
        this.requestId = response; // Assuming response has requestId
      } catch (error) {
        this.errorMessage = "Failed to send OTP. Please try again.";
      } finally {
        this.OTPisLoading = false;
      }
    },
    async handleOtpValidation() {
      this.isDisabled = true;
      this.loading = true;
      try {
        const response = await this.authStore.validateOtp(
          this.requestId,
          this.otp
        );
        console.log(response);

        // Check for policy acceptance
        if (response.data.policy_not_accepted) {
          this.policyAcceptance(response.data.token, response.data.email); // Call policyAcceptance with token and email
        } else {
          this.$emit('close');
          this.$router.push("/");
        } 
      } catch (error) {
        this.errorMessage = "Failed to validate OTP. Please try again.";
        this.isDisabled = false;
      } finally {
        this.loading = false;
      }
    },
    async handleEmailLogin() {
      this.loading = true;
      try {
        const response = await this.authStore.login(this.form);
        if (response.policy_not_accepted) {
          console.log("response", response);
          console.log("policy_not_accepted");
          this.policyAcceptance(response.token);
        } else {
          console.log("policy_accepted");
          this.$emit('close');
          this.$router.push("/");
        }
      } catch (error) {
        this.errorMessage = "Login failed. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    policyAcceptance(token, email = this.form.email) { // Updated to accept optional email
        this.$buefy.dialog.confirm({
            title: "Findhub Customer Policy",
            message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Fusce id fermentum quam. Proin sagittis,
                        nibh id hendrerit imperdiet, elit sapien laoreet elit,
                        ac scelerisque diam velit in nisl. Nunc maximus ex non
                        laoreet semper. Nunc scelerisque, libero sit amet pretium dignissim,
                        augue purus placerat justo,
                        sit amet porttitor dui metus in nisl.
                        Nulla non leo placerat, porta metus eu, laoreet risus.
                        Etiam lacinia, purus eu luctus maximus, elit ex viverra tellus,
                        sit amet sodales quam dui nec odio.
                        Nullam porta mollis est. Quisque aliquet malesuada fringilla.
                        Pellentesque volutpat lacus at ante posuere,
                        non pulvinar ante porta. Proin viverra eu massa nec porta.
                        Aliquam rhoncus velit quis sem hendrerit,
                        ut dictum nisl accumsan. Maecenas erat enim, scelerisque non ligula ac,
                        eleifend venenatis ligula.
                        Praesent molestie mauris sed elit posuere, non malesuada libero gravida.
                        In hac habitasse platea dictumst.
                        Pellentesque habitant morbi tristique senectus
                        et netus et malesuada fames ac turpis egestas.`,
            cancelText: "Disagree",
            confirmText: "Agree",
            type: "is-success",
            onConfirm: async () => {
                const response = await this.authStore.acceptPolicy(
                    email, // Use provided email or fallback to this.form.email
                    token
                );
                console.log("response from NAV 274", response);
                if (response.status === 200) {

                    this.$buefy.toast.open(response.data.message);
                    this.$emit('close');
                }
            },
        });
    },
    closeModal() {
      // Close the modal using Buefy's ModalProgrammatic method
      this.$parent.close();
    },
  },
  mounted() {
    hellojs.init({
      google: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirect_uri: "/oauth-redirect",
    });
  },
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input input[disabled] {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.navbar-item img {
  max-height: 3.75rem;
}
</style>
