<template>
  <b-navbar class="findhub_navbar">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="/logos/logo_name_slogan_bg_color@3x.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <!-- <template #start>
      <b-navbar-item href="#"> Home </b-navbar-item>
      <b-navbar-item href="#"> Documentation </b-navbar-item>
    </template> -->
    <template #end>
      <a class="navbar-item">
        <router-link to="/about">About</router-link>
      </a>
      <a class="navbar-item">
        <router-link to="/">Store</router-link>
      </a>
      <a class="navbar-item">
        <router-link to="/join-as-seller">Join As Seller</router-link>
      </a>
      <a class="navbar-item">
        <router-link to="/contact-us">Contact</router-link>
      </a>

      <b-navbar-item tag="div">
        <div class="buttons">
          <!-- <a
            class="button is-primary"
            v-if="!authStore.user"
            @click="showLoginModal"
          >
            Log in
          </a> -->
          <b-button
            v-if="!authStore.user"
            @click="cardModal"
            label="Login"
            type="is-primary"
          />

          <!-- <a v-else class="button is-light" @click="logout"> Log out </a> -->
          <b-dropdown
            v-else
            v-model="isComponentModalActive"
            position="is-bottom-left"
            append-to-body
            aria-role="menu"
            class="is-hidden-mobile"
          >
            <template #trigger>
              <a class="navbar-item is-primary" role="button">
                <figure class="image">
                  <img
                    class="is-rounded"
                    src="https://bulma.io/assets/images/placeholders/128x128.png"
                  />
                </figure>
              </a>
            </template>

            <b-dropdown-item custom aria-role="menuitem">
              Logged as <b>Rafael Beraldo</b>
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item has-link aria-role="menuitem">
              <a href="https://google.com" target="_blank" class="is-flex">
                <b-icon icon="link mr-1"></b-icon>
                Google (link)
              </a>
            </b-dropdown-item>
            <b-dropdown-item value="home" aria-role="menuitem" class="is-flex">
              <b-icon icon="home"></b-icon>
              Home
            </b-dropdown-item>
            <b-dropdown-item value="products" aria-role="menuitem">
              <b-icon pack="fas" icon="cart" size="is-small"></b-icon>
              Products
            </b-dropdown-item>
            <b-dropdown-item value="blog" disabled aria-role="menuitem">
              <b-icon pack="fas" icon="user" size="is-small"></b-icon>
              Blog
            </b-dropdown-item>
            <hr class="dropdown-divider" aria-role="menuitem" />
            <b-dropdown-item value="settings">
              <b-icon pack="fas" icon="cog" size="is-small"></b-icon>
              Settings
            </b-dropdown-item>
            <b-dropdown-item
              value="logout"
              aria-role="menuitem"
              @click="logout"
            >
              <b-icon icon="logout"></b-icon>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-navbar-item>
      <!-- <LoginModal v-model="isComponentModalActive" /> -->
      <!-- Bind modal visibility -->
    </template>
  </b-navbar>
</template>

<script>
/* eslint-disable */
//import { useRouter } from 'vue-router'
import { useAuthStore } from "../store/auth";
// import SampleDialog from './SampleDialog.vue'
// import VOtpInput from "vue3-otp-input";
import * as hellojs from "hellojs";
// import LoginModal from "./LoginModaBKPl.vue";
import LoginModal from "./LoginModal.vue";

export default {
  components: {
    LoginModal,
  },
  data() {
    return {
      isComponentModalActive: false,
      isLoginModalActive: false,
      activeTab: 0,
      mobile_number: "",
      otp: "",
      isOtpSent: false,
      isDisabled: false,
      requestId: null,
      loading: false,
      errorMessage: "",
      token: "",
      OTPisLoading: false,
      form: {
        email: "",
        password: "",
        device_name: "Web",
      },
    };
  },
  computed: {
    authStore() {
      return useAuthStore();
    },
  },
  methods: {
    logout() {
      this.authStore.logout();
      this.$router.push("/");
    },
    async handleInitialAuth() {
      this.OTPisLoading = true;
      try {
        const response = await this.authStore.initiateOtpAuth(
          this.mobile_number
        );
        this.isOtpSent = true;
        this.requestId = response; // Assuming response has requestId
      } catch (error) {
        this.errorMessage = "Failed to send OTP. Please try again.";
      } finally {
        this.OTPisLoading = false;
      }
    },
    async handleOtpValidation() {
      this.isDisabled = true;
      this.loading = true;
      try {
        const isVerified = await this.authStore.validateOtp(
          this.requestId,
          this.otp
        );
        if (isVerified) {
          this.isComponentModalActive = false;
          this.$router.push("/");
        } else {
          this.errorMessage = "OTP verification failed.";
          this.isDisabled = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to validate OTP. Please try again.";
        this.isDisabled = false;
      } finally {
        this.loading = false;
      }
    },
    async handleEmailLogin() {
      this.loading = true;
      try {
        const response = await this.authStore.login(this.form);
        if (response.policy_not_accepted) {
          console.log("response", response);
          console.log("policy_not_accepted");
          this.policyAcceptance(response.token);
        } else {
          console.log("policy_accepted");
          this.isComponentModalActive = false;
          this.$router.push("/");
        }
      } catch (error) {
        this.errorMessage = "Login failed. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    policyAcceptance(token) {
      this.$buefy.dialog.confirm({
        title: "Findhub Customer Policy",
        message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Fusce id fermentum quam. Proin sagittis,
                        nibh id hendrerit imperdiet, elit sapien laoreet elit,
                        ac scelerisque diam velit in nisl. Nunc maximus ex non
                        laoreet semper. Nunc scelerisque, libero sit amet pretium dignissim,
                        augue purus placerat justo,
                        sit amet porttitor dui metus in nisl.
                        Nulla non leo placerat, porta metus eu, laoreet risus.
                        Etiam lacinia, purus eu luctus maximus, elit ex viverra tellus,
                        sit amet sodales quam dui nec odio.
                        Nullam porta mollis est. Quisque aliquet malesuada fringilla.
                        Pellentesque volutpat lacus at ante posuere,
                        non pulvinar ante porta. Proin viverra eu massa nec porta.
                        Aliquam rhoncus velit quis sem hendrerit,
                        ut dictum nisl accumsan. Maecenas erat enim, scelerisque non ligula ac,
                        eleifend venenatis ligula.
                        Praesent molestie mauris sed elit posuere, non malesuada libero gravida.
                        In hac habitasse platea dictumst.
                        Pellentesque habitant morbi tristique senectus
                        et netus et malesuada fames ac turpis egestas.`,
        cancelText: "Disagree",
        confirmText: "Agree",
        type: "is-success",
        onConfirm: async () => {
          const response = await this.authStore.acceptPolicy(
            this.form.email,
            token
          );
          console.log("response from NAV 274", response);
          if (response.status === 200) {
            this.$buefy.toast.open(response.data.message);
            this.isComponentModalActive = false;
          }
        },
      });
    },
    cardModal() {
      this.$buefy.modal.open({
        parent: this,
        component: LoginModal,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });
    },
  },
  mounted() {
    hellojs.init({
      google: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirect_uri: "/oauth-redirect",
    });
  },
};
</script>

<style scoped>
.otp-input {
  display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    background-size: cover;
}

.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input input[disabled] {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.navbar-item img {
  max-height: 3.75rem;
}

.findhub_navbar {
  width: 100%;
  border-radius: .2rem .2rem 0.8rem .8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added box shadow */
}

</style>
