import { defineStore } from "pinia";
import { MapUtil } from "../utils/map.util";
import { requestFCMToken } from "../services/firebaseService";
import authService from "../services/auth.service"; // Import the auth service
import { useAuthStore } from "./auth"; // Import the auth store

export const useLocationStore = defineStore("location", {
  state: () => ({
    currentLocation: null,
    locationError: null,
    locationRequested: false,
    fcmToken: null,
    selectedLocation: { lat: null, lng: null }, // New state for selected location
  }),
  actions: {
    updateSelectedLocation(displayName, lat, lng) {
      this.currentLocation = { displayName, lat, lng };
    },
    setLocationError(error) {
      this.locationError = error;
    },
    setSelectedLocation(lat, lng) {
      // console.log("Before setting selectedLocation:", this.selectedLocation);
      this.selectedLocation = { lat, lng };
      // console.log("After setting selectedLocation:", this.selectedLocation);
    },
    checkingExistingLocationData() {
      const existingData =
        JSON.parse(localStorage.getItem("findhub_spa")) || {};
      if (existingData.lat && existingData.lng) {
        // If lat and lng are available, update the location without fetching from Google API
        this.updateSelectedLocation(
          existingData.displayName || "Saved Location", // Use a default display name if not available
          existingData.lat,
          existingData.lng
        );
        this.locationRequested = true; // Mark location as requested
        return true; // Indicate that location was updated
      }
      return false; // Indicate that no location data was found
    },
    async fetchCurrentLocation() {
      if (!("geolocation" in navigator)) {
        this.setLocationError("Geolocation is not supported by this browser.");
        return;
      }
      console.log("Fetching current location Called");

      // // Use the new function to check existing data
      // if (this.checkingExistingLocationData()) {
      //   return; // Exit if location was updated
      // }

      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        });
        //eslint-disable-next-line no-undef
        const { latitude, longitude } = position.coords;
        //eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        const response = await geocoder.geocode({
          location: { lat: latitude, lng: longitude },
          language: "en", // Specify English as the language
        });

        if (response.results[0]) {
          const placeInfo = response.results[0];
          const placeData = MapUtil.parseAddressComponents(
            placeInfo.address_components
          );
          const placeName = MapUtil.getLocalDisplayName(placeData); // Get the place name
          this.updateSelectedLocation(
            placeName,
            placeInfo.geometry.location.lat(),
            placeInfo.geometry.location.lng()
          );
          this.setCurrentLocation(
            placeInfo.geometry.location.lat(),
            placeInfo.geometry.location.lng(),
            placeName
          );
        } else {
          this.setLocationError("No results found");
        }
      } catch (error) {
        let errorMessage;
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage =
              "Location permission denied. Please enable location access in your browser settings.";
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = "Location information is unavailable.";
            break;
          case error.TIMEOUT:
            errorMessage = "The request to get user location timed out.";
            break;
          default:
            errorMessage =
              "An unknown error occurred while trying to get location.";
        }
        this.setLocationError(errorMessage);
        console.error("Geolocation error:", error);
      } finally {
        this.locationRequested = true;
      }
    },
    checkAndRequestLocation() {
      if (!this.checkingExistingLocationData()) {
        // Check if existing location data is available
        this.fetchCurrentLocation(); // Fetch current location if no existing data
      }
    },
    async registerFCMToken() {
      console.log("registerFCMToken");
      // Check if the user is authenticated and userData is available
      const authStore = useAuthStore(); // Access the auth store

      const userData = localStorage.getItem("findhub_spa"); // Updated to fetch userData from findhub_spa

      if (!authStore.isAuthenticated || !userData) {
        console.log("User is not authenticated or userData is not available.");
        return; // Exit if conditions are not met
      }

      const token = await requestFCMToken();
      if (token) {
        console.log("FCM Token:", token); // Log the FCM token for debugging
        this.fcmToken = token; // Store the FCM token in the state
        await authService.saveFcmToken(token); // Save the FCM token to the backend
      }
    },
    setCurrentLocation(lat, lng, displayName) {
      // Retrieve existing data from localStorage
      const existingData =
        JSON.parse(localStorage.getItem("findhub_spa")) || {};

      // Merge existing data with new location data
      const updatedData = {
        ...existingData,
        lat,
        lng,
        displayName,
      };

      // Store the updated data back in localStorage
      localStorage.setItem("findhub_spa", JSON.stringify(updatedData));

      // Update the store state
      this.updateSelectedLocation(displayName, lat, lng);
    },
    async fetchLocationDetailsByCoordinates(lat, lng) {
      // console.log("Fetching location details for given coordinates");

      try {
        //eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        const response = await geocoder.geocode({
          location: { lat, lng },
          language: "en",
        });

        if (response.results[0]) {
          const placeInfo = response.results[0];
          const addressComponents = placeInfo.address_components;

          // Extract street name, city name, and country name
          const street =
            addressComponents.find((component) =>
              component.types.includes("route")
            )?.long_name || "Unknown Street";
          const city =
            addressComponents.find((component) =>
              component.types.includes("locality")
            )?.long_name || "Unknown City";
          const state =
            addressComponents.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "Unknown State";
          const country =
            addressComponents.find((component) =>
              component.types.includes("country")
            )?.long_name || "Unknown Country";

          // Check if the location is in the United Arab Emirates
          if (country === "United Arab Emirates") {
            // console.log(
            //   `Street: ${street}, City: ${city}, State: ${state}, Country: ${country}`
            // );
            // Return the details as an object
            return {
              street: street,
              city: city,
              state: state,
              country: country,
            };
          } else {
            this.setLocationError(
              "Location is not in the United Arab Emirates."
            );
          }
        } else {
          this.setLocationError("No results found");
        }
      } catch (error) {
        this.setLocationError(
          "An error occurred while fetching location details."
        );
        console.error("Geolocation error:", error);
      }
    },
    autoFillForm(street, city, country) {
      // Implement form auto-fill logic here
      console.log(`Auto-filling form with: ${street}, ${city}, ${country}`);
      // Example: this.form.street = street;
      // Example: this.form.city = city;
      // Example: this.form.country = country;
    },
    async fetchLocationIDsByCoordinate(lat, lng) {
      // console.log("Fetching place IDs for given coordinates");

      try {
        //eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        const response = await geocoder.geocode({
          location: { lat, lng },
          language: "en",
        });

        if (response.results[0]) {
          const placeInfo = response.results[0];
          const addressComponents = placeInfo.address_components;

          // Extract place_id for the entire location
          const placeId = placeInfo.place_id;

          // Extract place_id for state and city
          const stateComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );

          const stateId = stateComponent ? stateComponent.place_id : null;
          const cityId = cityComponent ? cityComponent.place_id : null;

          // console.log(
          //   `Place ID: ${placeId}, State ID: ${stateId}, City ID: ${cityId}`
          // );
          return {
            placeId: placeId,
            stateId: stateId,
            cityId: cityId,
          };
        } else {
          this.setLocationError("No results found");
        }
      } catch (error) {
        this.setLocationError(
          "An error occurred while fetching the place IDs."
        );
        console.error("Geolocation error:", error);
      }
    },
  },
  mutations: {
    SET_FCM_TOKEN(state, token) {
      state.fcmToken = token;
    },
  },
});
