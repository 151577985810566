<template>
  <div class="container">
    <b-breadcrumb align="is-centered">
      <b-breadcrumb-item href="/">Home</b-breadcrumb-item>
      <b-breadcrumb-item href="/documentation/breadcrumb" active>
        About Us
      </b-breadcrumb-item>
    </b-breadcrumb>
    <section class="hero is-primary">
      <div class="hero-body">
        <p class="title">About Findhub</p>
        <p class="subtitle">Primary subtitle</p>
      </div>
    </section>
    <div class="content">
      <b-image
        src="/logos/name-with_slogan_1@2x.png"
        placeholder="https://picsum.photos/id/237/200/100?blur=10"
        class="image findhub_about_logo mt-3"
      ></b-image>
      <h5 class="title is-5">
        Findhub is a venture between two friends who struggled to find the right
        product in the UAE market that they were searching for. To read about
        their struggle and what led to the creation of Findhub app
      </h5>
      <h3 class="title is-3">Introduction</h3>
      <p>
        Founded by two colleagues in 2020, FindHub is just as the same suggests.
        An online UAE-based local search engine that uses the best of technology
        to bring you what you have been searching for. Minimum labour, easy use,
        and great accessibility are just some of the many features of our
        application. It doesn't get easier than this!
      </p>
      <h3 class="title is-3">Origin Story</h3>
      <p>
        2020 has been quite the year. The onset of a sudden pandemic and the
        subsequent lockdowns saw people confined to their houses with little or
        no avenue of stepping out or enjoying recreational activities such as
        shopping, picnics, etc.
      </p>
      <p>
        This particular lockdown also saw a sudden surge in the shopping
        frequency. As a result of this consumer shopping behaviour, we saw some
        unique patterns. People were now looking for products that they never
        had before or never needed to look for before. Most times they ended up
        with the wrong products that were shipped from far-off and unknown
        sources. We found ourselves in a similar predicament. We needed some
        very specific products that seemed out of reach. A local search for
        something small in the streets of Dubai turned into an expedition of
        sorts. We scoured every local market, combed through online platforms
        and stores, searched high and low in every lane, and continued to
        endeavour for a few days still.
      </p>
      <p>
        Finally, we had to resort to online platforms with international
        shipping before we discovered a wholesale vendor who had the product,
        right down our lane! So we decided to do just that. We created our own
        application to help people search for all the products and services they
        require, from verified sources without any hassle or discomfort. Our
        necessity gave way to FindHub. Our application made way for a number of
        satisfied users and vendors, alike.
      </p>
    </div>
    <div class="columns">
      <div class="column">
        <figure class="image is-128x128">
          <img src="https://bulma.io/assets/images/placeholders/256x256.png" />
        </figure>
        <h3 class="title is-3">Why Do We Need A Local Search Engine?</h3>
      </div>
      <div class="column">
        <p>
          Search engine use accounts for 93% of the web traffic currently.
          Simply put, everyone is constantly looking for something or the other.
          We've faced that struggle. We know the pain that goes into looking for
          that important item. However, it always seems out of reach due to
          unavailability or a lack of demand in the market.
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p>
          This is the exact problem we wish to address. An e-commerce search
          engine with the potential to turn into the best e-commerce search
          engine in helping you look for what you need without having to resort
          to travelling far and wide for it. A new radiator for your car? A
          repairman for that 5-year old AC? All you have to do is type it in. In
          short, we provide local search-related services in the UAE.
        </p>
      </div>
      <div class="column">
        <figure class="image is-128x128">
          <img src="https://bulma.io/assets/images/placeholders/256x256.png" />
        </figure>
        <h3 class="title is-3">Why Can't I Find What I Want Locally?</h3>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <figure class="image is-128x128">
          <img src="https://bulma.io/assets/images/placeholders/256x256.png" />
        </figure>
        <h3 class="title is-3">What Is Our Mission?</h3>
      </div>
      <div class="column">
        <p>
          We facilitate growth by getting the right people in touch. Great
          things happen when you go looking for the perfect item only to find it
          in the oddest of places. What started as a game of hunt is now a whole
          new platform to find the right fit. If you are looking for a product
          or service that you cannot find anywhere nearby, well, now you know
          where to look!. Findhub makes your search easier.
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p>
          People search for a million things a day. It can be an image search, a
          product search, or even a people search; there is nothing you cannot
          look for. We envision a future where this gap between a customer and a
          vendor is bridged successfully. We aim to be the best local search
          engine to find the exact thing you need.
        </p>
      </div>
      <div class="column">
        <figure class="image is-128x128">
          <img src="https://bulma.io/assets/images/placeholders/256x256.png" />
        </figure>
        <h3 class="title is-3">What Is Our Vision?</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "AboutUs",
  data() {
    return {};
  },
};
</script>

<style scoped>
.findhub_about_logo {
  max-width: 30rem;
}
</style>
