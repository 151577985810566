import axios from "axios";

export const refCodeService = {
  async getReferenceCodes(classifiers) {
    const response = await axios.get(`/api/customer/reference_codes`, {
      params: {
        classifiers: classifiers.join(","),
      },
    });
    return response;
  },
};
